@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .btn-primary {
        @apply focus:outline-none text-white text-center bg-alfa1-brand-primary-500 hover:bg-alfa1-brand-primary-700 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 hover:cursor-pointer disabled:cursor-not-allowed disabled:bg-gray-200;
    }

    .btn-secondary {
        @apply focus:outline-none text-white text-center bg-gray-500 hover:bg-gray-700 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 hover:cursor-pointer;
    }

    .input-txt {
        @apply bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-alfa1-brand-secondary-300 focus:ring-alfa1-brand-secondary-300 block w-full p-2.5;
    }

    .input-checkbox {
        @apply w-4 h-4 text-alfa1-brand-primary-500 bg-gray-100 border-gray-300 rounded focus:ring-alfa1-brand-primary-700 focus:ring-2 disabled:bg-gray-300;
    }
}